import * as React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import Button from "@mui/material/Button";
import HailIcon from "@mui/icons-material/Hail";

function WorkForUs() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));
  return (
    <div>
      <div id="WorkForUs" style={{ backgroundColor: "#fff", color: "white" }}>
        <Grid container sx={{ p: 1 }} alignItems={"center"}>
          <Grid item xs={12} sm={5} md={6}>
            <Box
              component={"img"}
              src="https://aci-website.s3.us-east-2.amazonaws.com/Images/JUSTIN.png"
              alt="Oxford Alabama"
              width={isMd ? "80%" : "70%"}
              sx={{
                borderRadius: 3,
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.6);",
              }}
            ></Box>
          </Grid>
          <Grid item xs={12} sm={7} md={6}>
            <h1
              style={{
                color: "#333333",
                fontSize: isSm ? 38 : isMd ? 42 : 50,
                margin: 0,
              }}
            >
              WANT TO WORK FOR US?
            </h1>
            <p
              style={{
                color: "#333333",
                fontSize: 22,
                textAlign: "center",
                margin: 0,
                paddingBottom: 10,
              }}
            >
              Come be part of an excellent team.{" "}
            </p>
            <Button
              sx={{
                backgroundColor: "#d96227",
              }}
              endIcon={<HailIcon />}
              variant="contained"
              size="large"
            >
              <a
                href="https://myjobs.adp.com/heico/cx/job-listing?keyword=Aerospace%20Coatings%20International"
                target="_blank"
                rel="noreferrer"
              >
                Apply Now!
              </a>
            </Button>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default WorkForUs;
