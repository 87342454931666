export const images = [
  {
    title: "AEROSPACE COATINGS",
    subtitle: "AN FAA CERTIFIED REPAIR STATION",
    img: "https://aci-website.s3.us-east-2.amazonaws.com/Images/SHOPFLOOR.png",
    buttonTitle: "Contact Us",
    buttonLink: "#ContactUs",
  },
  {
    title: "ADVANCED TECHNOLOGY",
    subtitle: "EQUIPPED WITH ADVANCED TECHNOLOGY TO HANDLE YOUR NEEDS",
    img: "https://aci-website.s3.us-east-2.amazonaws.com/Images/PlasmaSpray2.jpg",
    buttonTitle: "SEARCH REPAIRS",
    buttonLink: "#SEARCH REPAIRS",
  },
  {
    title: "WORK FOR US",
    subtitle: "COME BE PART OF OUR TEAM!",
    img: "https://aci-website.s3.us-east-2.amazonaws.com/Images/FriendlyShop.jpg",
    buttonTitle: "APPLY NOW",
    buttonLink:
      "https://myjobs.adp.com/heico/cx/job-listing?keyword=Aerospace%20Coatings%20International",
  },
];
