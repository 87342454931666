import http from "./http-common";

export const getPartImage = ({ queryKey }) => {
  const search = queryKey.length > 1 ? queryKey[1].partNumber : null;

  let url = "/image/partimage";

  if (!search) {
    return null;
  }

  url += `?partNumber=${search}`;
  return http.get(url);
};
