import s from "./Documents.module.css";
import * as React from "react";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

function Documents() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <div
      id="DOCUMENTS"
      className={s.documentsHeader}
      style={{
        backgroundColor: "#777",
        color: "white",
        paddingTop: 25,
        paddingBottom: 25,
      }}
    >
      <div>
        <Grid container alignContent={"center"}>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pb: 2,
                pt: 2,
              }}
            >
              <Box className={s.card}>
                <img
                  src="https://aci-website.s3.us-east-2.amazonaws.com/Images/Matt.jpg"
                  alt="FAA"
                  style={{
                    width: isSm ? "350px" : "400px",
                    borderRadius: "5px",
                  }}
                />
                <h4>FAA QUALITY DOCUMENTS</h4>
                <div className={s.container}>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_Certification.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAA Certification
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_Operations_Specifications.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAA Operation Specification
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_CapabilityList.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAA Capability List Manual
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_DRSQM.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAA DRSQM
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/SMSVP.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      FAA SMSVP Repair Station
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/QMS_Manual.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Quality Management System Manual
                    </a>
                  </p>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pb: 2,
                pt: 2,
              }}
            >
              <Box className={s.card}>
                <img
                  src="https://aci-website.s3.us-east-2.amazonaws.com/Images/PlasmaSpray.jpg"
                  alt="Plasma Spray"
                  style={{
                    width: isSm ? "350px" : "400px",
                    borderRadius: "5px",
                  }}
                />
                <h4>QUALITY DOCUMENTS</h4>
                <div className={s.container}>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/EASA_Certification.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      EASA Certification
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/EASA_Supplement.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      EASA Supplement
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_Certification.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CAAC Certification
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_Extension.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CAAC Extension
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_CAPABILITY.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CAAC Capability List
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CAAC_MAINTENANCE_MANUAL.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      CAAC Maintenance Orginization Manual
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/UK_CAA_Certificate.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      UK CAA Certification
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/UK_CAA_SUPPLEMENT.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      UK CAA SUPPLEMENT
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/AS9110C.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      AS9110C Certification
                    </a>
                  </p>
                </div>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={4}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-evenly",
                pb: 2,
                pt: 2,
              }}
            >
              <Box className={s.card}>
                <img
                  src="https://aci-website.s3.us-east-2.amazonaws.com/Images/Exchanges.jpg"
                  alt="EASA"
                  style={{
                    width: isSm ? "350px" : "400px",
                    borderRadius: "5px",
                  }}
                />
                <h4>OTHER</h4>
                <div className={s.container}>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/Purchase_Order_Terms_and_Conditions.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Purchase Order Terms and Conditions
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/Exchange_Clause.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Exchange Clause
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/WarrantyPolicy.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Warranty Policy
                    </a>
                  </p>
                  <p>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/TermsAndConditions.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      ACI Terms and Conditions
                    </a>
                    <a
                      className={(s.anchor, s.link)}
                      href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/ACI_CreditApp2011.pdf"
                      target="_blank"
                      rel="noreferrer"
                    >
                      Credit Application
                    </a>
                  </p>
                </div>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Documents;
