import * as React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import CssBaseline from "@mui/material/CssBaseline";
import Drawer from "@mui/material/Drawer";
import IconButton from "@mui/material/IconButton";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import MenuIcon from "@mui/icons-material/Menu";
import Toolbar from "@mui/material/Toolbar";
import logo from "../public/aciLogoBlue.png";
import s from "./Header.module.css";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import HailIcon from "@mui/icons-material/Hail";

const navItems = [
  "HOME",
  "DOCUMENTS",
  "CAPABILITIES & REPAIRS",
  "SERVICES",
  "CONTACT US",
];

export default function Header() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen((prevState) => !prevState);
  };

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        {navItems.map((item, index) => (
          <ListItem key={index} disablePadding>
            <ListItemButton divider component="a" href={"#" + item}>
              <div className={s.link}>{item}</div>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );

  return (
    <Box sx={{ display: "flex" }}>
      <CssBaseline />
      <AppBar
        component="nav"
        sx={{
          zIndex: (theme) => theme.zIndex.drawer + 1,
          backgroundColor: "white",
          boxShadow: mobileOpen ? "none" : "0 4px 8px 0 rgba(0,0,0,0.6)",
        }}
      >
        <Toolbar
          sx={{
            display: "flex",
            justifyContent: isSm ? "space-between" : "space-between",
          }}
        >
          <Box>
            <img
              src={logo}
              className="App-logo"
              alt="logo"
              style={{ height: isSm ? "55px" : "75px" }}
            />
          </Box>
          <Box
            sx={{
              display: { xs: "none", sm: "block" },
            }}
          >
            <ul
              style={{
                color: "#0076BD",
                fontWeight: "bold",
                textAlign: "right",
              }}
            >
              {navItems.map((item, index) => (
                <li key={index} style={{ display: "inline", marginRight: 20 }}>
                  <a className={s.link} href={"#" + item}>
                    {item}
                  </a>
                </li>
              ))}
              <li style={{ display: "inline", marginRight: 20 }}>
                <a
                  className={s.link}
                  href="https://www.aerocoatings.com/customerconsole/Login.asp"
                  target="_blank"
                  rel="noreferrer"
                >
                  CUSTOMER CONSOLE
                </a>
              </li>
            </ul>
          </Box>
          <Button
            sx={{
              display: { xs: "none", sm: "none", md: "flex" },
              backgroundColor: "#d96227",
            }}
            endIcon={<HailIcon />}
            variant="contained"
          >
            <a
              href="https://myjobs.adp.com/heico/cx/job-listing?keyword=Aerospace%20Coatings%20International"
              target="_blank"
              rel="noreferrer"
            >
              Apply Now!
            </a>
          </Button>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerToggle}
            sx={{ display: { sm: "none" } }}
          >
            <MenuIcon style={{ backgroundColor: "rgb(25, 90, 146)" }} />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Box component="nav">
        <Drawer
          variant="temporary"
          open={mobileOpen}
          onClose={handleDrawerToggle}
          anchor="top"
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: "block", sm: "none" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              top: 61,
            },
          }}
        >
          {drawer}
        </Drawer>
      </Box>
    </Box>
  );
}
