import React, { useState } from "react";
import s from "./Carousel.module.css";
import { images } from "../helpers/CarouselData.js";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { Button, useMediaQuery, useTheme } from "@mui/material";

const Carousel = () => {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  const [style] = useState(s.carouselInner);
  let [currImg, setCurrImg] = useState(0);

  const backImage = () => {
    setCurrImg((oldState) =>
      oldState - 1 === -1 ? images.length - 1 : oldState - 1
    );
  };
  const forwardImage = () => {
    setCurrImg((oldState) =>
      oldState + 1 > images.length - 1 ? 0 : oldState + 1
    );
  };

  return (
    <div className={s.carousel}>
      <div
        className={s.carouselInner}
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${images[currImg].img})`,
        }}
      >
        <div id="HOME" className={style}>
          <div className={s.left} onClick={backImage}>
            <ArrowBackIosIcon style={{ fontSize: 30 }} />
          </div>
          <div className={s.center} style={{ textAlign: "left" }}>
            <div className={s.dropInDiv}>
              <h1
                style={{
                  fontSize: isSm ? 24 : isMd ? 44 : 54,
                }}
              >
                {images[currImg].title}
              </h1>
              <p style={{ fontSize: isSm ? 18 : isMd ? 26 : 32 }}>
                {images[currImg].subtitle}
              </p>
              <Button
                sx={{ backgroundColor: "#d96227" }}
                variant="contained"
                href={images[currImg].buttonLink}
              >
                {images[currImg].buttonTitle}
              </Button>
            </div>
          </div>
          <div className={s.right} onClick={forwardImage}>
            <ArrowForwardIosIcon style={{ fontSize: 30 }} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Carousel;
