import * as React from "react";
import useWindowDimensions from "../hooks/useWindowDimensions";

function Marketing() {
  const { width } = useWindowDimensions();
  return (
    <div
      id="Marketing"
      style={{ backgroundColor: "#333", padding: 0, marginBottom: -6 }}
    >
      <video style={{ objectFit: "initial" }} width={width} controls>
        <source
          src="https://aci-website.s3.us-east-2.amazonaws.com/Images/ACIVideo.mp4"
          type="video/mp4"
        />
        Your browser does not support the video tag.
      </video>
    </div>
  );
}

export default Marketing;
