import s from "./SearchRepairs.module.css";
import * as React from "react";
import { Paper, TextField, useMediaQuery, useTheme } from "@mui/material";
import { Box } from "@mui/material";
import { Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { Button } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { getCapabilities } from "../services/caps-search";
import { getERIs } from "../services/eri-search";
import { Grid } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import CloseIcon from "@mui/icons-material/Close";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import { getPartImage } from "../services/image-search";
import pnImageFotFound from "../images/pn-image-not-found.png";

function SearchRepairs() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const [tabIndex, setTabIndex] = useState(0);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [imageSearch, setImage] = useState({ partNumber: "" });
  const imageQuery = useQuery(["images", imageSearch], getPartImage, {
    refetchOnWindowFocus: false,
    retry: false,
  });

  const handleClick = (event, partNumber) => {
    setAnchorEl(event.currentTarget);
    setImage({ partNumber });
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  //Clear Search Button States
  const [showButton, setShowButton] = useState(false);
  const [showERIButton, setShowERIButton] = useState(false);

  //Input States
  const [search, setSearch] = useState({ partNumber: "", partDesc: "" });
  const [search2, setSearch2] = useState({ term: "" });

  const handleTabChange = (event, newTabIndex) => {
    setTabIndex(newTabIndex);
    setSearch({ partNumber: "", partDesc: "" });
    setSearch2({ term: "" });
  };

  const capsSearch = useQuery(["capsSearch", search], getCapabilities, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const clearResult = () => {
    setSearch({ partNumber: "" });
    setShowButton(false);
  };

  const clearERI = () => {
    setSearch2({ term: "" });
    setShowERIButton(false);
  };

  const submitCapsSearch = () => {
    capsSearch.refetch();
    setShowButton(true);
  };

  const eriSearch = useQuery(["eriSearch", search2], getERIs, {
    enabled: false,
    refetchOnWindowFocus: false,
  });

  const submitEriSearch = (e) => {
    eriSearch.refetch();
    setShowERIButton(true);
  };

  return (
    <div
      style={{ backgroundColor: "#fff", color: "white" }}
      className={s.searchRepair}
      id="CAPABILITIES & REPAIRS"
    >
      <div className={s.searchRepairDiv}>
        <Grid container justifyContent={"center"} sx={{ pb: 2 }}>
          <Grid item xs={12} sm={12} md={12} lg={8}>
            <h1 style={{ fontSize: isSm ? 32 : 50, margin: 0 }}>
              SEARCH OUR CAPABILITIES & REPAIRS
            </h1>

            <Box style={{ display: "flex", justifyContent: "center" }}>
              <Tabs value={tabIndex} onChange={handleTabChange}>
                <Tab label="Capabilities" />
                <Tab
                  label={
                    isSm
                      ? "Engineering"
                      : "Engineering Repair Instruction (ERI)"
                  }
                />
              </Tabs>
            </Box>

            <Box
              style={{
                backgroundColor: "#f4f4f4",
                paddingTop: 1,
                paddingBottom: 20,
                paddingLeft: 20,
                paddingRight: 20,
                borderRadius: 6,
              }}
            >
              {tabIndex === 0 && (
                <Box>
                  <div>
                    <div
                      style={{
                        backgroundColor: "#f4f4f4",
                        borderBottom: "1px solid #D3D3D3",
                      }}
                    >
                      <h3 style={{ color: "#333", textAlign: "left" }}>
                        Search: Capabilities
                        <a
                          href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/FAA_CapabilityList.pdf"
                          rel="noreferrer"
                          style={{
                            color: "#0076BD",
                            fontWeight: "bold",
                            textDecoration: "none",
                            fontSize: 12,
                          }}
                        >
                          {" "}
                          Download FAA Capability List (PDF)
                        </a>
                      </h3>
                    </div>
                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                        display: "flex",
                      }}
                    >
                      <TextField
                        fullWidth
                        variant="filled"
                        margin="normal"
                        helperText="Search by Part Number or Description"
                        id="fullWidth"
                        value={search.partNumber}
                        onChange={(event) => {
                          setSearch((oldState) => ({
                            ...oldState,
                            partNumber: event.target.value,
                          }));
                        }}
                      />
                    </Box>

                    <div style={{ textAlign: "left", marginTop: 9 }}>
                      <Button
                        style={{
                          backgroundColor: "rgb(217,98,39)",
                          color: "white",
                        }}
                        size="large"
                        onClick={submitCapsSearch}
                      >
                        Search
                      </Button>
                      {showButton && (
                        <Button
                          style={{
                            backgroundColor: "rgb(211,211,211)",
                            color: "white",
                            marginLeft: "5px",
                          }}
                          onClick={clearResult}
                          size="large"
                        >
                          <CloseIcon /> Clear
                        </Button>
                      )}
                    </div>
                  </div>
                </Box>
              )}
              {tabIndex === 1 && (
                <Box>
                  <div>
                    <div
                      style={{
                        backgroundColor: "#f4f4f4",
                        borderBottom: "1px solid #D3D3D3",
                      }}
                    >
                      <h3 style={{ color: "#333", textAlign: "left" }}>
                        Search: ERIs{" "}
                      </h3>
                    </div>

                    <Box
                      sx={{
                        width: "100%",
                        maxWidth: "100%",
                      }}
                    >
                      <TextField
                        variant="filled"
                        fullWidth
                        margin="normal"
                        id="fullWidth"
                        value={search2.term}
                        helperText="Search by Part Number"
                        onChange={(event) => {
                          setSearch2((oldState) => ({
                            ...oldState,
                            term: event.target.value,
                          }));
                        }}
                      />
                    </Box>
                    <div style={{ textAlign: "left", marginTop: 9 }}>
                      <Button
                        style={{
                          backgroundColor: "rgb(217,98,39)",
                          color: "white",
                        }}
                        size="large"
                        onClick={submitEriSearch}
                      >
                        Search
                      </Button>

                      {showERIButton && (
                        <Button
                          style={{
                            backgroundColor: "rgb(211,211,211)",
                            color: "white",
                            marginLeft: "5px",
                          }}
                          size="large"
                          onClick={clearERI}
                        >
                          <CloseIcon /> Clear
                        </Button>
                      )}
                    </div>
                  </div>
                </Box>
              )}
              {capsSearch.isSuccess && (
                <Box>
                  <h3 style={{ marginTop: 0 }}>Capability Search Results</h3>
                  <TableContainer component={Paper}>
                    <Table size="small" stickyHeader>
                      <TableHead>
                        <TableRow>
                          <TableCell>Part Number</TableCell>
                          <TableCell>Part Description</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {capsSearch.data.data.capability.map((item) => (
                          <TableRow>
                            <TableCell>
                              <Button
                                key={item.partNumber}
                                aria-describedby={id}
                                variant="text"
                                sx={{ whiteSpace: "nowrap", p: 0 }}
                                onMouseEnter={(event) => {
                                  handleClick(event, item.partNumber);
                                }}
                                onMouseLeave={(event) => {
                                  handleClose();
                                }}
                              >
                                {item.partNumber}
                              </Button>
                            </TableCell>
                            <Popover
                              disableRestoreFocus
                              sx={{
                                pointerEvents: "none",
                              }}
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography
                                sx={{ p: 1 }}
                                style={{ width: "200px", height: "200px" }}
                              >
                                {imageQuery.isFetched &&
                                  imageQuery.isSuccess &&
                                  imageQuery.data && (
                                    <img
                                      alt="yes"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      src={imageQuery.data.data.URL}
                                    />
                                  )}
                                {imageQuery.isFetched && imageQuery.isError && (
                                  <img
                                    alt="yes"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    src={pnImageFotFound}
                                  />
                                )}
                              </Typography>
                            </Popover>
                            <TableCell>{item.description}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
              {eriSearch.isSuccess && (
                <Box>
                  <h3 style={{ marginTop: 0 }}>ERI Search Results</h3>
                  <TableContainer
                    sx={{ maxHeight: isSm ? 450 : 750 }}
                    component={Paper}
                  >
                    <Table
                      size="small"
                      id="eriSearchBoxScrollPoint"
                      stickyHeader
                    >
                      <TableHead>
                        <TableRow>
                          <TableCell>ERI&nbsp;#</TableCell>
                          <TableCell>Part Info</TableCell>
                          <TableCell>NHA</TableCell>
                          <TableCell>ATA Code</TableCell>
                          <TableCell>ATA Ref</TableCell>
                          <TableCell>OEM</TableCell>
                          <TableCell>Aircraft</TableCell>
                          <TableCell>Life Limited</TableCell>
                          <TableCell>FAA Classification</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {eriSearch.data.data.eri.map((item) => (
                          <TableRow key={item.derino}>
                            <TableCell>{item.derino}</TableCell>
                            <TableCell>
                              {item.partNumber &&
                                item.partNumber.map((partNumber) => (
                                  <Button
                                    key={partNumber}
                                    aria-describedby={id}
                                    variant="text"
                                    sx={{ whiteSpace: "nowrap", p: 0 }}
                                    onMouseEnter={(event) => {
                                      handleClick(event, partNumber);
                                    }}
                                    onMouseLeave={(event) => {
                                      handleClose();
                                    }}
                                  >
                                    {partNumber}
                                  </Button>
                                ))}
                              <Box
                                component={"div"}
                                sx={{ fontSize: "0.75rem" }}
                              >
                                {item.partDescription}
                              </Box>
                            </TableCell>
                            <Popover
                              disableRestoreFocus
                              sx={{
                                pointerEvents: "none",
                              }}
                              id={id}
                              open={open}
                              anchorEl={anchorEl}
                              onClose={handleClose}
                              anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "left",
                              }}
                            >
                              <Typography
                                sx={{ p: 1 }}
                                style={{ width: "200px", height: "200px" }}
                              >
                                {imageQuery.isFetched &&
                                  imageQuery.isSuccess &&
                                  imageQuery.data && (
                                    <img
                                      alt="yes"
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                      src={imageQuery.data.data.URL}
                                    />
                                  )}
                                {imageQuery.isFetched && imageQuery.isError && (
                                  <img
                                    alt="yes"
                                    style={{
                                      width: "100%",
                                      height: "100%",
                                    }}
                                    src={pnImageFotFound}
                                  />
                                )}
                              </Typography>
                            </Popover>
                            <TableCell>{item.nha}</TableCell>
                            <TableCell>{item.source}</TableCell>
                            <TableCell>{item.system}</TableCell>
                            <TableCell>{item.oem}</TableCell>
                            <TableCell>{item.aircraft}</TableCell>
                            <TableCell>
                              {item.lifeLimited ? "Yes" : "No"}
                            </TableCell>
                            <TableCell>
                              {item.faaRepairClassification}
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default SearchRepairs;
