import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";

function ContactUs() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));
  const isMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <div id="CONTACT US" style={{ backgroundColor: "#195A92", color: "white" }}>
      <Grid container sx={{ p: 1 }} alignItems={"center"}>
        <Grid item xs={12} sm={12} md={7}>
          <Grid container alignItems={"flex-start"}>
            <Grid item xs={12}>
              <h1
                style={{
                  fontSize: isSm ? 32 : 50,
                  textAlign: "left",
                  margin: 0,
                }}
              >
                Contact Us
              </h1>
            </Grid>
            <Grid container alignItems={"flex-start"}>
              <Grid item xs={6} sm={6} md={6}>
                <Box sx={{ pb: 1 }}>
                  <p style={{ fontSize: isSm ? 18 : 24, margin: 0 }}>
                    <b>Located At</b>
                  </p>
                  <Box sx={{ pl: 0.45 }}>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      370 Knight Drive
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Oxford, AL 36203
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      (256) 241-2750
                    </p>
                  </Box>
                </Box>
                <Box sx={{ pb: 1 }}>
                  <p style={{ fontSize: isSm ? 18 : 24, margin: 0 }}>
                    <b>Email</b>
                  </p>
                  <Box sx={{ pl: 0.45 }}>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Human Resources
                    </p>
                    <p style={{ margin: 0 }}>
                      <a
                        href="mailto:hr@aerocoatings.com"
                        rel="noreferrer"
                        style={{
                          fontSize: isSm ? 16 : 20,
                          margin: 0,
                          paddingLeft: "5px",
                        }}
                        target="_blank"
                      >
                        hr@aerocoatings.com
                      </a>
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Customer Service
                    </p>
                    <p style={{ margin: 0 }}>
                      <a
                        href="mailto:customerservice@aerocoatings.com"
                        style={{
                          fontSize: isSm ? 16 : 20,
                          margin: 0,
                          paddingLeft: "5px",
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        customerservice@aerocoatings.com
                      </a>
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>Sales</p>
                    <p style={{ margin: 0 }}>
                      <a
                        href="mailto:sales@aerocoatings.com"
                        style={{
                          fontSize: isSm ? 16 : 20,
                          margin: 0,
                          paddingLeft: "5px",
                        }}
                        target="_blank"
                        rel="noreferrer"
                      >
                        sales@aerocoatings.com
                      </a>
                    </p>
                  </Box>
                </Box>
              </Grid>
              <Grid item xs={6} sm={6} md={6}>
                <Box sx={{ pb: 1 }}>
                  <p style={{ fontSize: isSm ? 18 : 24, margin: 0 }}>
                    <b>Hours</b>
                  </p>
                  <Box sx={{ pl: 0.45 }}>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Mon-Fri 8AM - 4PM
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Saturday Closed
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Sunday Closed
                    </p>
                  </Box>
                </Box>
                <Box sx={{ pb: 1 }}>
                  <p style={{ fontSize: isSm ? 18 : 24, margin: 0 }}>
                    <b>Mailing Address</b>
                  </p>
                  <Box sx={{ pl: 0.45 }}>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      PO Box 7340
                    </p>
                    <p style={{ fontSize: isSm ? 16 : 20, margin: 0 }}>
                      Oxford, AL 36203
                    </p>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={6} md={5}>
          <Box
            component={"img"}
            src="https://aci-website.s3.us-east-2.amazonaws.com/Images/ACI.jpg"
            alt="Oxford Alabama"
            width={isMd ? "90%" : "80%"}
            sx={{ borderRadius: 3, boxShadow: "0 4px 8px 0 rgba(0,0,0,0.6);" }}
          ></Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default ContactUs;
