import s from "./Services.module.css";
import { Box, Grid, useMediaQuery, useTheme } from "@mui/material";
import EngineeringIcon from "@mui/icons-material/Engineering";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ScatterPlotIcon from "@mui/icons-material/ScatterPlot";
import SettingsIcon from "@mui/icons-material/Settings";
import PrecisionManufacturingIcon from "@mui/icons-material/PrecisionManufacturing";
import ShieldIcon from "@mui/icons-material/Shield";

function Services() {
  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  const engineering = [
    "Repair Development",
    "DER Approval",
    "Nondestructive Material Analysis",
    "Reverse Engineering",
    "Technical Documentation",
  ];
  const qualityAssurance = [
    "Flourescent Penetrant Inspection",
    "Magnetic Particle Inspection",
    "NDT Personnel Qualifications Program",
    "Nital Etch Inspection",
    "Rockwell Hardness Testing",
    "Shotpeen",
  ];
  const platings = [
    "Chrome Plating",
    "LHE Cadmium Plating",
    "Ti-Cad Plating",
    "Bronze Plating",
    "Copper Plating",
    "Acid Cadmium Plating",
    "Zinc Phosphate Plating",
    "Manganese Phosphate Plating",
    "Sulfamate Nickel Plating",
    "Silver Plating",
  ];
  const coatings = [
    "Black Oxide",
    "HYSOL",
    "Dry Film Lubrication",
    "Plasma/HVOF Spray",
    "Chromate Conversion",
    "Electroless Nickel",
    "Welding",
    "Passivation",
  ];
  const blastMedia = [
    "Steel Shot",
    "Glass Bead",
    "Sand Blast",
    "Rubber Bead Blast",
    "Pulverized Walnut Shells",
  ];
  const grindingMachining = [
    "Cylindrical Grinding",
    "Internal Grinding",
    "Jig Grinding",
    "Honing",
    "EDM",
    "Full Machine Shop Capabilties CNC/Conventional",
    "Superfinish",
  ];

  return (
    <div id="SERVICES">
      <div
        style={{
          backgroundColor: "#195A92",
          color: "white",
          paddingBottom: 20,
          paddingTop: 10,
        }}
      >
        <h1 style={{ fontSize: isSm ? 32 : 50, margin: 0 }}>SERVICES</h1>

        <Grid
          container
          justifyContent={"space-evenly"}
          alignItems={"flex-start"}
          columnSpacing={{ xs: 0.5, sm: 2 }}
          rowSpacing={{ xs: 0.5, sm: 2 }}
          sx={{ p: 1 }}
        >
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <div className={s.card}>
              <EngineeringIcon style={{ fontSize: 50, margin: 0 }} />
              <h3 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                ENGINEERING
              </h3>
              {engineering.map((item, i) => (
                <Box key={i} sx={{ textAlign: "left", pb: 0.5 }}>
                  {item}
                </Box>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <div className={s.card}>
              <ThumbUpOffAltIcon style={{ fontSize: 50 }} />
              <h3 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                QUALITY ASSURANCE
              </h3>
              {qualityAssurance.map((item, i) => (
                <Box key={i} sx={{ textAlign: "left", pb: 0.5 }}>
                  {item}
                </Box>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <div className={s.card}>
              <SettingsIcon style={{ fontSize: 50 }} />
              <h3 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                PLATINGS
              </h3>
              {platings.map((item, i) => (
                <Box key={i} sx={{ textAlign: "left", pb: 0.5 }}>
                  {item}
                </Box>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <div className={s.card}>
              <ShieldIcon style={{ fontSize: 50 }} />
              <h3 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                COATINGS
              </h3>
              {coatings.map((item, i) => (
                <Box key={i} sx={{ textAlign: "left", pb: 0.5 }}>
                  {item}
                </Box>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <div className={s.card}>
              <ScatterPlotIcon style={{ fontSize: 50 }} />
              <h3 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                BLAST MEDIA
              </h3>
              {blastMedia.map((item, i) => (
                <Box key={i} sx={{ textAlign: "left", pb: 0.5 }}>
                  {item}
                </Box>
              ))}
            </div>
          </Grid>
          <Grid item xs={6} sm={4} md={4} lg={2}>
            <div className={s.card}>
              <PrecisionManufacturingIcon style={{ fontSize: 50 }} />
              <h3 style={{ margin: 0, padding: 0, marginBottom: 5 }}>
                GRINDING & MACHINING
              </h3>
              {grindingMachining.map((item, i) => (
                <Box key={i} sx={{ textAlign: "left", pb: 0.5 }}>
                  {item}
                </Box>
              ))}
            </div>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default Services;
