import http from "./http-common";

export const getERIs = ({ queryKey }) => {
  const search = queryKey.length > 1 ? queryKey[1].term : null;

  let url = "/eri/search";

  if (search) url += `?term=${search}`;
  return http.get(url);
};
