import s from "./Footer.module.css";
import { Box, Grid, Link, Typography } from "@mui/material";
import Facebook from "@mui/icons-material/Facebook";
import Instagram from "@mui/icons-material/Instagram";
import LinkedIn from "@mui/icons-material/LinkedIn";

function Footer() {
  return (
    <div className={s.footer}>
      <Grid container justifyContent={"space-between"} sx={{ p: 2 }}>
        <Grid item xs={6} sm={3} md={3} sx={{ pb: 1 }}>
          <Typography variant="body1">Located At</Typography>
          <Box sx={{ pl: 0.25 }}>
            <Typography variant="body2">
              370 Knight Drive <br />
              Oxford, AL 36203
              <br />
              hr@aerocoatings.com
              <br />
              <b>O:</b> 256.241.2750
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} md={3} sx={{ pb: 1 }}>
          <Typography variant="body1">Company</Typography>
          <Box sx={{ pl: 0.25 }}>
            <Typography variant="body2">
              <Link href="#WhoWeAre" underline="hover" color="inherit">
                About
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link href="#ContactUs" underline="hover" color="inherit">
                Contact
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                underline="hover"
                color="inherit"
                href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/CookiePolicy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Cookie Policy
              </Link>
            </Typography>
            <Typography variant="body2">
              <Link
                underline="hover"
                color="inherit"
                href="https://aci-website.s3.us-east-2.amazonaws.com/Documents/PrivacyPolicy.pdf"
                target="_blank"
                rel="noreferrer"
              >
                Privacy Policy
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Typography variant="body1">Careers</Typography>
          <Box sx={{ pl: 0.25 }}>
            <Typography variant="body2">
              <Link
                href="https://recruiting.adp.com/srccar/public/RTI.home?_fromPublish=true&c=1215401&d=ExternalCareerSite#/"
                target="_blank"
                rel="noreferrer"
                underline="hover"
                color="inherit"
              >
                View Job Openings
              </Link>
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={6} sm={3} md={3}>
          <Typography variant="body1">Find Us on Social Media</Typography>
          <Box sx={{ pl: 0.25 }}>
            <Typography variant="body2">
              <Link
                href="http://www.facebook.com/aerocoatings"
                target="_blank"
                rel="noreferrer"
                underline="hover"
                color="inherit"
              >
                <Facebook />
              </Link>
              <Link
                href="https://www.instagram.com/aerospace_coatings/"
                target="_blank"
                rel="noreferrer"
                underline="hover"
                color="inherit"
              >
                <Instagram />
              </Link>
              <Link
                href="https://www.linkedin.com/company/aerospace-coatings-international-inc-/mycompany/"
                target="_blank"
                rel="noreferrer"
                underline="hover"
                color="inherit"
              >
                <LinkedIn />
              </Link>
            </Typography>
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}

export default Footer;
