import Header from "./components/Header";
import Footer from "./components/Footer";
import Carousel from "./components/Carousel";
import Documents from "./components/Documents";
import WhoWeAre from "./components/WhoWeAre";
import Location from "./components/Location";
import WorkForUs from "./components/WorkForUs";
import Services from "./components/Services";
import Marketing from "./components/Marketing";
import ContactUs from "./components/ContactUs";
import "./App.css";
import React from "react";
import "typeface-roboto";
import "@fontsource/jost"; // Defaults to weight 400
import "@fontsource/jost/400.css"; // Specify weight
import "@fontsource/jost/400-italic.css"; // Specify weight and style
import { Button, useMediaQuery, useTheme } from "@mui/material";
import SearchRepairs from "./components/SearchRepairs";
import HailIcon from "@mui/icons-material/Hail";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

function App() {
  const queryClient = new QueryClient();

  const theme = useTheme();
  const isSm = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <QueryClientProvider client={queryClient}>
      <div className="App background">
        <div className="container1">
          <header className="App-header">
            <Header />
          </header>
          <Carousel />
          <Location />
          <div style={{ height: isSm ? "50px" : "150px" }}></div>
          <WhoWeAre />
          <Marketing />
          <SearchRepairs />
          <Services />
          <Documents />
          <WorkForUs />
          <ContactUs />
          <div style={{ height: isSm ? "50px" : "150px" }}></div>
          <Button
            sx={{
              display: { xs: "absolute", sm: "absolute", md: "none" },
              backgroundColor: "#d96227",
              boxShadow: "0 4px 8px 0 rgba(0,0,0,0.6)",
              margin: 0,
              top: "auto",
              right: 20,
              bottom: 20,
              left: "auto",
              position: "fixed",
            }}
            endIcon={<HailIcon />}
            variant="contained"
          >
            <a
              href="https://myjobs.adp.com/heico/cx/job-listing?keyword=Aerospace%20Coatings%20International"
              target="_blank"
              rel="noreferrer"
            >
              Apply Now!
            </a>
          </Button>
          <Footer />
          <div
            style={{
              backgroundColor: "white",
              color: "#195A92",
              padding: 10,
              fontSize: isSm ? 12 : 16,
              textAlign: "center",
            }}
          >
            &#169; Aerospace Coatings International {new Date().getFullYear()}.
            All rights reserved.{" "}
          </div>
        </div>
      </div>
    </QueryClientProvider>
  );
}

export default App;
